@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: white;
  font-family: "Titillium Web", sans-serif;
  color: #454545;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}
